import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Timestamp, doc, getFirestore, onSnapshot, serverTimestamp, setDoc } from "firebase/firestore";


export function handleCart() {
    onAuthStateChanged(getAuth(), (user) => {
        let unsub = null
        const cart_status_els = document.querySelectorAll('.cart .cart-status')
        if (user) {
            const cartDocRef = doc(getFirestore(), "carts", user.uid, "user_cart", user.uid)
            unsub = onSnapshot(cartDocRef, (doc) => {
                if (doc.exists()) {
                    const data = doc.data()
                    if (data.hasOwnProperty('lines')) {
                        cart_status_els.forEach(el => el.innerText = Object.keys(data.lines).length)
                    }
                    else {
                        cart_status_els.forEach(el => el.innerText = '0')
                    }
                }
                else {
                    setDoc(cartDocRef, {
                        createdTimestamp: serverTimestamp(),
                        createdTimestampLocal: Timestamp.now(),
                        createdUser: user.uid,
                        createdApp: process.env.FBASE_APP_ID,
                        createdAppOrigin: location.origin || ''
                    })
                    cart_status_els.forEach(el => el.innerText = '0')
                }
            })
        }
        else {
            if (unsub) unsub()
            cart_status_els.forEach(el => el.innerText = '0')
        }
    })
}