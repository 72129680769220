import { handleCart } from "./utils/navbar-cart"
import { addListnerForAuthStateVisibility, handleSignOut, initFirebase } from "./utils/fbase"
import addNavbarBurgerListner from "./utils/navbar-burger"

initFirebase()

document.addEventListener('DOMContentLoaded', () => {
    console.log("DOMContentLoaded")
    console.log(process.env.FBASE_APP_ID)

    addNavbarBurgerListner()
    addListnerForAuthStateVisibility()
    handleCart()
    try {
        const btn_signout_el = document.getElementById('btn_signout')
        btn_signout_el.addEventListener('click', () => {
            btn_signout_el.classList.add('is-loading')
            handleSignOut()
            btn_signout_el.classList.remove('is-loading')
        })
    } catch (error) {
        console.error("App: Could not add click event listner to #btn_signout.", error)
    }
})